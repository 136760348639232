import { audiotekaApi } from "./AudiotekaApi";
import {
  AttachmentList,
  Audiobook,
  Catalog,
  Category,
  CategoryList,
  CycleList,
  DownloadableAudiobook,
  Favourites,
  HomeScreen,
  LicenseChannels,
  PodcastList,
  Product,
  ProductList,
  ProductRelations,
  ProductReview,
  ProductReviewList,
  SearchScreen,
  HelpCenterScreen,
  UserRecentlyPlayed,
  UserShelf,
} from "./AudiotekaApi.types";

const endpoints = {
  audiobook: (audiobookId: string) => `/audiobooks/${audiobookId}`,
  product: (productId: string) => `/products/${productId}`,
  catalog: (catalogId: string) => `/visitor/${catalogId}/screen/catalog`,
  category: (categoryId: string) => `/categories/${categoryId}`,
  categoryList: "/categories",
  categoryProductList: (categoryId: string) => `/categories/${categoryId}/products`,
  cycleList: "/cycles",
  homeScreen: (catalogId: string) => `/visitor/${catalogId}/screen`,
  searchScreen: (catalogId: string) => `/visitor/${catalogId}/screen/search`,
  helpCenterScreen: (catalogId: string) => `/visitor/${catalogId}/screen/help-center`,
  podcastList: "/podcasts",
  attachmentList: (audiobookId: string) => `/audiobooks/${audiobookId}/attachments`,
  licenseChannels: (audiobookId: string) => `/me/audiobook-license-channels/${audiobookId}`,
  productReviewList: (catalogId: string, audiobookId: string) =>
    `/catalogs/${catalogId}/products/${audiobookId}/reviews`,
  productReview: (productId: string) => `/me/products/${productId}/review`,
  searchSlug: (type: string) => `/search/${type}/get-slug`,
  userCatalog: "/me/screen/catalog",
  userHomeScreen: "/me/screen",
  userFavourites: "/me/favourites",
  userRecentlyPlayed: "/me/recently-played",
  userShelf: "/me/shelf",
};

const commands = {
  audiobookDownload: "RequestAudiobookDownload",
  rateAndReviewProduct: "RateAndReviewProduct",
  rateProduct: "RateProduct",
  removeFromFavourites: "RemoveFromFavourites",
  addToFavourites: "AddToFavourites",
  getProductRelations: "GetProductRelations",
} as const;

/*
  GET
*/

export const getHomeScreen = (catalogId: string) =>
  audiotekaApi.get<HomeScreen>({ url: endpoints.homeScreen(catalogId), params: { "_f[noLinks]": 1 } });

export const getProduct = (productId: string, catalogId: string) =>
  audiotekaApi.get<Product>({ url: endpoints.product(productId), params: { catalog_id: catalogId } });

export const getAudiobook = (audiobookId: string, catalogId: string) =>
  audiotekaApi.get<Audiobook>({ url: endpoints.audiobook(audiobookId), params: { catalog: catalogId } });

export const getScreenCatalog = (catalogId: string) => audiotekaApi.get<Catalog>({ url: endpoints.catalog(catalogId) });

export const getCategory = (categoryId: string, catalogId: string) =>
  audiotekaApi.get<Category>({
    url: endpoints.category(categoryId),
    params: { catalog: catalogId },
  });

export const getCategoryList = (catalogId: string) =>
  audiotekaApi.get<CategoryList>({
    url: endpoints.categoryList,
    params: { catalog: catalogId },
  });

export const getCycleList = (
  catalogId: string,
  params: {
    page?: number;
    limit?: number;
  }
) => audiotekaApi.get<CycleList>({ url: endpoints.cycleList, params: { ...params, catalog: catalogId } });

export const getPodcastList = (
  catalogId: string,
  params: {
    page?: number;
    limit?: number;
  }
) => audiotekaApi.get<PodcastList>({ url: endpoints.podcastList, params: { ...params, catalog: catalogId } });

export const getProductReviewList = (catalogId: string, audiobookId: string) =>
  audiotekaApi.get<ProductReviewList>({
    url: endpoints.productReviewList(catalogId, audiobookId),
  });

export const getCategoryProductList = (
  catalogId: string,
  categoryId: string,
  params: {
    club?: boolean;
    limit?: number;
    order?: string;
    page?: number;
    sort?: string;
  }
) =>
  audiotekaApi.get<ProductList>({
    url: endpoints.categoryProductList(categoryId),
    params: { ...params, catalog: catalogId },
  });

export const getAttachmentList = (audiobookId: string) =>
  audiotekaApi.get<AttachmentList>({
    url: endpoints.attachmentList(audiobookId),
  });

export const getUserProductReview = (productId: string) =>
  audiotekaApi.get<ProductReview>({
    url: endpoints.productReview(productId),
  });

export const getLicensesChannels = (audiobookId: string) =>
  audiotekaApi.get<LicenseChannels>({
    url: endpoints.licenseChannels(audiobookId),
  });

export const getUserCatalog = () => audiotekaApi.get<Catalog>({ url: endpoints.userCatalog });

export const getUserHomeScreen = () =>
  audiotekaApi.get<HomeScreen>({
    url: endpoints.userHomeScreen,
    params: { "_f[noLinks]": 1 },
  });

export const getReviewsPage = ({
  catalogId,
  productId,
  page,
  limit,
}: {
  catalogId: string;
  productId: string;
  page: number;
  limit?: number;
}) =>
  audiotekaApi.get<ProductReviewList>({
    url: endpoints.productReviewList(catalogId, productId),
    params: { page, limit },
  });

export const getSlug = (type: string, query: string) => {
  return audiotekaApi.get<{ slug: string }>({ url: endpoints.searchSlug(type), params: { query } });
};

export const getFavouritesCount = () =>
  audiotekaApi.get<Pick<Favourites, "total">>({
    url: endpoints.userFavourites,
    params: { "_f[noEmbeds]": 1, "_f[noLinks]": 1, limit: 1 },
  });

export const getUserRecentlyPlayed = () => audiotekaApi.get<UserRecentlyPlayed>({ url: endpoints.userRecentlyPlayed });

export const getUserShelf = (params?: { page: number; limit?: number; sort?: string; order?: "desc" | "asc" }) =>
  audiotekaApi.get<UserShelf>({ url: endpoints.userShelf, params });

export const getSearchScreen = (catalogId: string) =>
  audiotekaApi.get<SearchScreen>({ url: endpoints.searchScreen(catalogId) });

export const getHelpCenterScreen = (catalogId: string) =>
  audiotekaApi.get<HelpCenterScreen>({ url: endpoints.helpCenterScreen(catalogId) });

/*
  POST
*/

export const audiobookDownloadCommands = (audiobookId: string) =>
  audiotekaApi.sendCommand<{ name: typeof commands.audiobookDownload; audiobook_id: string }, DownloadableAudiobook>({
    name: commands.audiobookDownload,
    audiobook_id: audiobookId,
  });

export const rateAndReviewProductCommands = ({
  productId,
  review,
  signature,
  rating,
}: {
  productId: string;
  review: string;
  signature: string;
  rating: number;
}) =>
  audiotekaApi.sendCommand<
    {
      name: typeof commands.rateAndReviewProduct;
      product_id: string;
      review: string;
      author_signature: string;
      rating: number;
    },
    {}
  >({ name: commands.rateAndReviewProduct, product_id: productId, review, author_signature: signature, rating });

export const rateProductCommands = ({ productId, rating }: { productId: string; rating: number }) =>
  audiotekaApi.sendCommand<{ name: typeof commands.rateProduct; product_id: string; rating: number }, {}>({
    name: commands.rateProduct,
    product_id: productId,
    rating,
  });

export const removeFromFavourites = (productId: string) =>
  audiotekaApi.sendCommand<{ name: typeof commands.removeFromFavourites; product_id: string }, {}>({
    name: commands.removeFromFavourites,
    product_id: productId,
  });

export const addToFavourites = (productId: string) =>
  audiotekaApi.sendCommand<{ name: typeof commands.addToFavourites; product_id: string }, {}>({
    name: commands.addToFavourites,
    product_id: productId,
  });

export const getProductRelations = (productIds: string[]) =>
  audiotekaApi.sendCommand<{ name: typeof commands.getProductRelations; product_ids: string[] }, ProductRelations>({
    name: commands.getProductRelations,
    product_ids: productIds,
  });
