import React, { useEffect, useRef } from "react";
// eslint-disable-next-line import/no-unresolved
import { GoogleTagManager } from "@next/third-parties/google";
import { useRouter } from "next/router";
import cookie from "js-cookie";
import dynamic from "next/dynamic";
import gtm from "react-gtm-module";
import Head from "next/head";
import Script from "next/script";
import { useLocale, useTranslations } from "next-intl";

import { Layout } from "components/Layout/Layout";
import { isServer } from "utils/runtime";
import { getPathname } from "pathnames";
import { useUser } from "modules/user";

import { AppContainerProps } from "./App.types";
import { useSignInRedirectParams } from "./useSignInRedirectParams";

const isBetaModalTurnedOffByQueryParam = () => {
  if (isServer) return false;

  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("nobetamodal") === "true";
};

const BetaModal = dynamic(() => import("modules/Beta/Modal"), {
  ssr: false,
});

const PageWrapper = ({
  Component,
  catalogItems,
  pageProps,
}: Pick<AppContainerProps, "Component" | "catalogItems" | "pageProps">) => {
  const { disableLayout = false, getLayout } = Component.layoutConfig || {};

  if (disableLayout) {
    return <Component {...pageProps} />;
  }

  if (getLayout) {
    return <Layout catalogItems={catalogItems}>{getLayout(<Component {...pageProps} />)}</Layout>;
  }

  return (
    <Layout catalogItems={catalogItems}>
      <Component {...pageProps} />
    </Layout>
  );
};

export function AppContainer({ Component, pageProps = {}, disableRender, catalogItems }: AppContainerProps) {
  const gtmInitRef = useRef(false);
  const { asPath, route } = useRouter();
  const t = useTranslations();
  const user = useUser();
  const locale = useLocale();

  const { setRedirectTo } = useSignInRedirectParams();

  if (disableRender) {
    return null;
  }

  const { _canonical = `${asPath.replace(/\?(.*)$/, "")}`, _robots = "" } = pageProps;

  const shouldShowBetaModal =
    !isServer && window.innerWidth >= 768 && cookie.get("w3intro") !== "1" && !isBetaModalTurnedOffByQueryParam();

  useEffect(() => {
    switch (route) {
      case getPathname("home").slice(0, -1):
        setRedirectTo(""); // Clear redirectTo on home
        break;
      case getPathname("signIn"):
      case getPathname("signUp"):
      case getPathname("passwordReset"):
        // Don't change redirectTo on auth pages
        break;
      default:
        setRedirectTo(`${asPath}`);
    }
  }, [asPath, route]);

  useEffect(() => {
    if (!gtmInitRef.current && !user.isLoading) {
      gtmInitRef.current = true;
      gtm.initialize({ gtmId: process.env.GTM_ID });
    }
  }, [user.isLoading]);

  return (
    <>
      <Head>
        <title>{t("meta.home.title")}</title>
        <meta name="robots" content={`${_robots && `${_robots}, `}max-image-preview:large`} />
        <meta name="description" content={t("meta.home.description")} />
        <meta key="og-title" property="og:title" content={t("meta.home.title")} />
        <meta key="og-description" property="og:description" content={t("meta.home.description")} />
        <meta
          key="og-image"
          property="og:image"
          content="https://fn.audioteka.com/pl/go/ogimage.jpg?locale=pl&v=2020-12-15"
        />
        <link key="canonical" rel="canonical" href={`${process.env.SITE_URL}${_canonical}`} />
      </Head>

      <PageWrapper Component={Component} pageProps={pageProps} catalogItems={catalogItems} />
      {shouldShowBetaModal && <BetaModal />}

      <Script id="wpjslib-config">
        {`
          var wp_onepager = true;
          var wp_sn = 'audioteka';
          var wp_consent_color = '#33ADFF';
          var wp_consent_text = '${t.markup("wp-consent-text", {
            b: (chunks) => `<strong>${chunks}</strong>`,
          })}<br><br>';
          var wp_consent_logo = 'https://atkcdn.com/audioteka/global/logo-transparent.png';
          var wp_consent_blur = 0;
          window.wp_cookie_info = false;
        `}
      </Script>
      <Script async crossOrigin="" noModule defer src="https://std.wpcdn.pl/wpjslib/wpjslib-lite.js" id="wpjslib" />
      <Script async crossOrigin="" type="module" src="https://std.wpcdn.pl/wpjslib6/wpjslib-lite.js" id="wpjslib6" />
      {locale === "cz" && <GoogleTagManager gtmId="GTM-TJ5G95" />}
    </>
  );
}
