export function getLocationInfo(locale: string) {
  switch (locale) {
    case "pl":
      return { catalogId: "eb098df9-0967-4e80-b2c1-469dde554d5f", currency: "PLN", features: { kids: true } };
    case "de":
      return { catalogId: "922cc401-0d61-42d4-8c08-c76890b4ce68", currency: "EUR", features: { kids: false } };
    case "cz":
      return { catalogId: "34662dc3-9ae9-4add-9117-07cae2b6e224", currency: "CZK", features: { kids: false } };
    case "sk":
      return { catalogId: "84240810-b58f-4d89-94a1-95549b3e13a8", currency: "EUR", features: { kids: false } };
    case "lt":
      return { catalogId: "12b459b9-4305-4e87-a1a3-a69f9b5248bc", currency: "EUR", features: { kids: false } };
    case "tr":
      return { catalogId: "2e075ff1-d6c1-4477-b1c9-4425a35295ce", currency: "TRY", features: { kids: false } };
    default:
      return { catalogId: "", currency: "", features: { kids: false } };
  }
}
