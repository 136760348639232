import React, { ComponentProps } from "react";
import NextLink from "next/link";
import { useLocale } from "next-intl";

import { getPathname } from "pathnames";
import { buildLink } from "utils/buildLink";

import "./link.scss";

interface Props extends Omit<ComponentProps<typeof NextLink>, "as" | "href"> {
  params?: Record<string, string>;
  route: Parameters<typeof getPathname>[0];
}

export function Link({ children, className, params, route, ...props }: Props) {
  const locale = useLocale();

  return (
    <NextLink
      as={buildLink(route, locale, params)}
      className={className}
      href={{ pathname: getPathname(route), query: { locale, ...params } }}
      {...props}
    >
      {children}
    </NextLink>
  );
}
