import { getPathname } from "pathnames";

export { MenuMobile } from "./menu-mobile";
export { MenuMobileContent } from "./menu-mobile-content";
export { MenuMobileHeader } from "./menu-mobile-header";

export interface MenuMobileItem {
  name: string;
  accent?: boolean;
  route?: Parameters<typeof getPathname>[0];
  params?: Record<string, string>;
  href?: string;
}
