import React from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { Link } from "app-components/link";
import { NewIcon } from "components/NewIcon";

import type { MenuMobileItem } from "../MenuMobile";
import { MenuMobile, MenuMobileContent, MenuMobileHeader } from "../MenuMobile";
import css from "./catalog-menu.module.scss";

interface Props {
  open: boolean;
  items: MenuMobileItem[];
  onClose: () => void;
}

export function CatalogMenu({ items, open, onClose }: Props) {
  const t = useTranslations();

  return (
    <MenuMobile open={open}>
      <MenuMobileHeader onClose={onClose}>
        <Link className={css.title} route="catalog" onClick={onClose}>
          <NewIcon icon="menu" width="24" height="24" />
          <div>{t("navigation.catalog")}</div>
        </Link>
      </MenuMobileHeader>
      <MenuMobileContent>
        {items.map((item) => (
          <Link
            key={item.name}
            className={classNames(css.link, { [css.accent]: item.accent })}
            route={item.route}
            params={item.params}
            title={item.name}
            onClick={onClose}
          >
            {item.name}
          </Link>
        ))}
      </MenuMobileContent>
    </MenuMobile>
  );
}
