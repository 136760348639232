import React, { useState } from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { Link } from "app-components/link";
import { NewIcon } from "components/NewIcon";
import { useUser } from "modules/user";
import { useSignInRedirectParams } from "modules/App/useSignInRedirectParams";
import { ButtonPrimitive } from "app-components/button/button-primitive";

import { CartButton } from "./CartButton";
import { FavouriteButton } from "./FavouriteButton";
import Dropdown from "./Dropdown/Dropdown";
import { trackUpperRightMenuClickEvent } from "./trackUpperRightMenuClickEvent";
import { UserMenu } from "./UserMenu/UserMenu";
import css from "./icon-buttons.module.scss";

export const IconButtons = () => {
  const { isLoggedIn } = useUser();
  const t = useTranslations();
  const { signInRedirectParams } = useSignInRedirectParams();

  const [userMenuVisible, setUserMenuVisible] = useState(false);

  const toggleUserMenu = () => {
    setUserMenuVisible((visible) => !visible);
  };

  return (
    <>
      <div className={css.iconButtons}>
        <ul className={css.list}>
          <li className={classNames(css.listItem, css.showOnMobile)}>
            <CartButton />
          </li>
          <li className={css.listItem}>
            <FavouriteButton />
          </li>
          <li className={css.listItem}>
            <Link
              className={css.menuLink}
              route="shelf"
              title={t("navigation.my_shelf")}
              onClick={() => trackUpperRightMenuClickEvent("collection")}
            >
              <NewIcon className={css.icon} icon="bookshelf" width="24" height="24" />
            </Link>
          </li>
          <li className={css.listItem}>
            <Link
              className={css.menuLink}
              route="recentlyPlayed"
              title={t("navigation.recently_played")}
              onClick={() => trackUpperRightMenuClickEvent("last_listen")}
            >
              <NewIcon className={css.icon} icon="history" width="24" height="24" />
            </Link>
          </li>

          {isLoggedIn ? (
            <li className={classNames(css.listItem, css.showOnMobileOnly)}>
              <ButtonPrimitive onClick={toggleUserMenu}>
                <NewIcon className={css.icon} icon="user" width="24" height="24" />
              </ButtonPrimitive>
            </li>
          ) : (
            <li className={classNames(css.listItem, css.showOnMobileOnly)}>
              <Link
                className={css.loginButton}
                route="signIn"
                params={signInRedirectParams}
                title={t("navigation.sign_in")}
                onClick={() => trackUpperRightMenuClickEvent("login")}
              >
                <NewIcon className={css.icon} icon="user" width="24" height="24" />
              </Link>
            </li>
          )}

          {isLoggedIn ? (
            <li className={css.listItem}>
              <Dropdown />
            </li>
          ) : (
            <li className={css.listItem}>
              <Link
                className={css.loginButton}
                route="signIn"
                params={signInRedirectParams}
                title={t("navigation.sign_in")}
                onClick={() => trackUpperRightMenuClickEvent("login")}
              >
                <NewIcon className={css.icon} icon="user-alt" width="18" height="18" />
                {t("navigation.sign_in")}
              </Link>
            </li>
          )}
        </ul>
      </div>

      <UserMenu open={userMenuVisible} onClose={() => setUserMenuVisible(false)} />
    </>
  );
};
