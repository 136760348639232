import React from "react";

import { Teaser } from "modules/DesignSystem/components/teaser";
import TeaserList from "modules/DesignSystem/components/TeaserList";

import css from "./search-results.module.scss";

interface Props {
  limit?: number;
  mobileLimit?: number;
  results: HALProduct[];
}

export const SearchResults = ({ limit, mobileLimit, results }: Props) => {
  const desktopResults = limit ? results.slice(0, limit) : results;
  const mobileResults = mobileLimit ? results.slice(0, mobileLimit) : results;

  return (
    <div>
      <div className={css.mobileGrid}>
        {mobileResults.map((product) => (
          <Teaser key={product.data.id} product={product} />
        ))}
      </div>
      <TeaserList className={css.teaserList} elements={desktopResults} />
    </div>
  );
};
